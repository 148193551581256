import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from '../components/navbar';
import { Footer } from '../components/footer';

import '../styles/global.css';
import SEO from '../SEO';
import { pageMeta } from '../utils/common';
import CardPengaduan from '../components/cardPengaduan';
import CONTACT_CONFIG from '../configs/contactConfig';
import Wrapper from '../components/Wrapper';

const { PT_CONTACT, FOOTER_CONTACT } = CONTACT_CONFIG;

export default function HubungiKami() {
  return (
    <Wrapper>
      <SEO
        title="Hubungi Kami"
        metaDescription={pageMeta.tentangDesc}
      />
      <Navbar />
      <div className="container mx-auto px-0 pt-10 xl:max-w-screen-xl">
        <h1 className="font-bold text-black text-center text-3xl lg:text-5xl">Apakah Anda Memiliki Pertanyaan?</h1>
        <p className="text-center mx-4 lg:w-4/6 lg:mx-auto lg:my-3">
          PT. KOMUNAL SEJAHTERA INDONESIA berkomitmen untuk terus memberikan pelayanan yang terbaik,
          aman, dan nyaman untuk Anda. Berikut adalah beberapa hal yang perlu Anda ketahui mengenai
          media dan skema pengaduan di PT. KOMUNAL SEJAHTERA INDONESIA .
        </p>
      </div>
      <div className="container mx-auto lg:flex flex-row justify-between my-4 lg:my-6 lg:w-3/6">
        <div className="text-center">
          <StaticImage
            src="../images/email-icon.png"
            alt="email-icon"
            objectFit="contain"
            className="w-5"
            placeholder="none"
          />
          <p className="font-semibold my-2">Email</p>
          <a className="lg:py-2" href={`mailto:${PT_CONTACT.EMAIL}`}>{PT_CONTACT.EMAIL}</a>
        </div>
        <div className="text-center py-4 lg:py-0">
          <StaticImage
            src="../images/telephone-icon.png"
            alt="email-icon"
            objectFit="contain"
            className="w-5"
            placeholder="none"
          />
          <p className="font-semibold my-2">Telepon</p>
          <a className="lg:py-2">{PT_CONTACT.PHONE}</a>
        </div>
        <div className="text-center">
          <StaticImage
            src="../images/whatsapp-icon.png"
            alt="email-icon"
            objectFit="contain"
            className="w-5"
            placeholder="none"
          />
          <p className="font-semibold my-2">Whatsapp</p>
          <a className="lg:py-2" href={FOOTER_CONTACT.WHATSAPP_LINK} target="_blank" rel="noreferrer">
            {PT_CONTACT.WHATSAPP}
          </a>
        </div>
      </div>
      <div className="container mx-auto py-5 xl:max-w-screen-xl">
        <div className="w-full">
          <div className="py-2 google-map-container flex justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.635155396
                816!2d112.68453221477492!3d-7.282287294743789!2m3!1f0!2f0!3f0!3m2!1i1024!2
                i768!4f13.1!3m3!1m2!1s0x2dd7fd0d499e76f9%3A0x3499a0b11d0c3065!2sPT%20Komunal
                %20Sejahtera%20Indonesia%20-%20Deposito%20BPR!5e0!3m2!1sen!2sid!4v1630053146839!5m2!1sen!2sid"
              allowFullScreen="true"
              title="komunal address"
              loading="lazy"
              className="iframe self-center"
            />
          </div>
        </div>
      </div>
      <CardPengaduan />
      <Footer showConnectWithUs={false} />
    </Wrapper>
  );
}
